var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('b-card', {
    staticClass: "mb-1 box-shadow-0 border p-1",
    attrs: {
      "id": "traing-ticket-list",
      "no-body": ""
    }
  }, [_c('TrainTicketListFilter', {
    attrs: {
      "search-text-filter": _vm.searchTextFilter,
      "booking-code-filter": _vm.bookingCodeFilter,
      "created-by-filter": _vm.createdByFilter,
      "agency-code-filter": _vm.agencyCodeFilter,
      "start-date-filter": _vm.startDateFilter,
      "end-date-filter": _vm.endDateFilter,
      "start-trip-date-filter": _vm.startTripDateFilter,
      "end-trip-date-filter": _vm.endTripDateFilter,
      "statusFilter": _vm.statusFilter,
      "status-options": _vm.statusTrainOptions,
      "empty-filter": _vm.isEmptyFilter
    },
    on: {
      "update:searchTextFilter": function updateSearchTextFilter($event) {
        _vm.searchTextFilter = $event;
      },
      "update:search-text-filter": function updateSearchTextFilter($event) {
        _vm.searchTextFilter = $event;
      },
      "update:bookingCodeFilter": function updateBookingCodeFilter($event) {
        _vm.bookingCodeFilter = $event;
      },
      "update:booking-code-filter": function updateBookingCodeFilter($event) {
        _vm.bookingCodeFilter = $event;
      },
      "update:createdByFilter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "update:created-by-filter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "update:agencyCodeFilter": function updateAgencyCodeFilter($event) {
        _vm.agencyCodeFilter = $event;
      },
      "update:agency-code-filter": function updateAgencyCodeFilter($event) {
        _vm.agencyCodeFilter = $event;
      },
      "update:startDateFilter": function updateStartDateFilter($event) {
        _vm.startDateFilter = $event;
      },
      "update:start-date-filter": function updateStartDateFilter($event) {
        _vm.startDateFilter = $event;
      },
      "update:endDateFilter": function updateEndDateFilter($event) {
        _vm.endDateFilter = $event;
      },
      "update:end-date-filter": function updateEndDateFilter($event) {
        _vm.endDateFilter = $event;
      },
      "update:startTripDateFilter": function updateStartTripDateFilter($event) {
        _vm.startTripDateFilter = $event;
      },
      "update:start-trip-date-filter": function updateStartTripDateFilter($event) {
        _vm.startTripDateFilter = $event;
      },
      "update:endTripDateFilter": function updateEndTripDateFilter($event) {
        _vm.endTripDateFilter = $event;
      },
      "update:end-trip-date-filter": function updateEndTripDateFilter($event) {
        _vm.endTripDateFilter = $event;
      },
      "update:statusFilter": function updateStatusFilter($event) {
        _vm.statusFilter = $event;
      },
      "update:status-filter": function updateStatusFilter($event) {
        _vm.statusFilter = $event;
      },
      "fetch-data": _vm.refetchData,
      "reset": _vm.clearFilter
    }
  }), _c('b-table', {
    ref: "refTrainTicketListTable",
    staticClass: "position-relative p-0 rounded",
    style: _vm.maxHeightStyle,
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchBookingList,
      "striped": true,
      "fields": _vm.tableColumns,
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "hover": true,
      "borderless": true,
      "busy": _vm.loading,
      "responsive": "",
      "no-border-collapse": "",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "small": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, _vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$te("train.list.columns.".concat(data.label)) ? _vm.$t("train.list.columns.".concat(data.label)) : data.label) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('span', {
          staticClass: "align-text-top text-capitalize font-weight-bold d-flex-center",
          staticStyle: {
            "color": "#333333"
          }
        }, [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.sizePerPage + data.index + 1) + " ")])])];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info pb-0",
          attrs: {
            "to": {
              name: 'apps-train-ticketDetail',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.bookingCode) + " ")]), _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window.top",
            value: _vm.$t('copy'),
            expression: "$t('copy')",
            modifiers: {
              "hover": true,
              "window": true,
              "top": true
            }
          }],
          staticClass: "p-50 rounded-circle ml-25",
          attrs: {
            "variant": "flat-primary",
            "disabled": _vm.disabledCopy
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(data.item.bookingCode);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "copy",
            "size": "18",
            "color": "#2FA4E5"
          }
        })], 1)], 1)];
      }
    }, {
      key: "cell(identifyNumber)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', [_vm._v(" " + _vm._s(data.item.identifyNumber) + " ")]), _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window.top",
            value: _vm.$t('copy'),
            expression: "$t('copy')",
            modifiers: {
              "hover": true,
              "window": true,
              "top": true
            }
          }],
          staticClass: "p-50 rounded-circle ml-25",
          attrs: {
            "variant": "flat-primary",
            "disabled": _vm.disabledCopy
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(data.item.identifyNumber);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "copy",
            "size": "18",
            "color": "#2FA4E5"
          }
        })], 1)], 1)];
      }
    }, {
      key: "cell(bookingInfos)",
      fn: function fn(data) {
        return _vm._l(data.item.bookingInfos, function (bookingInfo, index) {
          return _c('div', {
            key: index,
            staticClass: "d-flex flex-nowrap gap-1"
          }, [_c('span', [_vm._v(_vm._s(bookingInfo.departure) + "-" + _vm._s(bookingInfo.arrival))]), _c('span', [_vm._v(_vm._s(_vm.resolveTime(bookingInfo.departureTime, bookingInfo.departureHour)) + "-" + _vm._s(_vm.resolveTime(bookingInfo.arrivalTime, bookingInfo.arrivalHour)))]), _c('span', [_vm._v(_vm._s(bookingInfo.trainCode))])]);
        });
      }
    }, {
      key: "cell(totalPrice)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(_vm._s(_vm.formatCurrency(data.item.totalPrice)))])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(_vm._s(_vm.convertISODateTime(data.item.createdAt).time))]), _c('div', [_vm._v(_vm._s(_vm.convertISODateTime(data.item.createdAt).date))])];
      }
    }, {
      key: "cell(createdBy)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex-center flex-column",
          attrs: {
            "id": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt)
          }
        }, [_c('div', {
          staticClass: "text-info font-weight-bold text-body-3 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.createdBy.username.toUpperCase()) + " ")]), _c('div', {
          staticClass: "text-dark font-weight-bold text-body-3 text-nowrap"
        }, [_vm._v(" (" + _vm._s(_vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? data.item.createdBy.agency.parentAgency.agencyCode.toUpperCase() : data.item.createdBy.agency.agencyCode.toUpperCase()) + ") ")]), _c('b-tooltip', {
          attrs: {
            "target": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt),
            "triggers": "hover",
            "placement": "top",
            "boundary": "viewport",
            "variant": "dark"
          }
        }, [_c('BRow', [_c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Tên tài khoản: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(data.item.createdBy.lastName) + " " + _vm._s(data.item.createdBy.firstName) + " (" + _vm._s(data.item.createdBy.type) + ") ")])]), _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Mã tài khoản: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.employeeCode.toUpperCase()))])]), _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Tên đại lý: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.agency.agencyName))])]), _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Mã đại lý: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.agency.agencyCode.toUpperCase()))])]), _vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Tên ĐL chủ quản: ")]) : _vm._e(), _vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.agency.parentAgency.agencyName))])]) : _vm._e(), _vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Mã ĐL chủ quản: ")]) : _vm._e(), _vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.agency.parentAgency.agencyCode.toUpperCase()))])]) : _vm._e()], 1)], 1)], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        var _data$item;
        return [_c('b-badge', {
          class: "badge-glow round text-uppercase",
          attrs: {
            "variant": "light-".concat(_vm.resolveTrainTicketStatusVariant(data.item.status))
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("train.status.".concat((_data$item = data.item) === null || _data$item === void 0 ? void 0 : _data$item.status))) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center gap-2"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "variant": "flat-info",
            "title": _vm.isMobileView ? '' : _vm.$t('')
          },
          on: {
            "click": _vm.featureDevelopment
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "eye",
            "size": "20"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "variant": "flat-info",
            "title": _vm.isMobileView ? '' : _vm.$t('')
          },
          on: {
            "click": _vm.featureDevelopment
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "fileArrowDown",
            "size": "20"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "variant": "flat-info",
            "title": _vm.isMobileView ? '' : _vm.$t('')
          },
          on: {
            "click": _vm.featureDevelopment
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "undo",
            "size": "20"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, true)
  }), _c('div', {
    staticClass: "mx-2 my-1"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex-center justify-content-md-start",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.showing')) + " "), _c('b-dropdown', {
    staticClass: "per-page-selector d-inline-block p-0 mx-25",
    attrs: {
      "variant": "warning",
      "text": "".concat(_vm.sizePerPage)
    },
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }, _vm._l(_vm.sizePerPageLgOptions, function (item) {
    return _c('b-dropdown-item', {
      key: item,
      on: {
        "click": function click($event) {
          _vm.sizePerPage = item;
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1), _vm._v(" " + _vm._s(_vm.$t('tickets')) + " "), _c('div', [_vm._v(_vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('tickets')))])], 1)]), _c('b-col', {
    staticClass: "d-flex-center justify-content-md-end mt-1 mt-md-0 pr-lg-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-pagination', {
    staticClass: "pagination-warning mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalTickets,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }