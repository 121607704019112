<template>
  <div class="mb-50">
    <!-- ANCHOR - Filter Booking -->
    <b-row>
      <!-- ANCHOR book date -->
      <b-col
        cols="12"
        md="4"
        lg="3"
      >
        <b-form-group class="w-100 mb-0">
          <b-input-group id="range-date">
            <flat-pickr
              v-model="rangeDate"
              class="form-control form-control-md"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
                altFormat: 'd-m-Y',
                class: 'form-control-md',
                altInput: true,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              :placeholder="$t('train.filter.bookDatePlaceholder')"
            />

            <b-input-group-append is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- ANCHOR flight date -->
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mt-75 mt-md-0"
      >
        <b-form-group class="w-100 mb-0">
          <b-input-group id="range-date">
            <flat-pickr
              v-model="rangeTripDate"
              class="form-control form-control-md"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
                altFormat: 'd-m-Y',
                altInput: true,
                class: 'form-control-md',
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              :placeholder="$t('train.filter.tripDatePlaceholder')"
            />

            <b-input-group-append is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- ANCHOR: Status -->
      <b-col
        cols="12"
        md="4"
        lg="2"
        class="mt-75 mt-md-0"
      >
        <b-form-group
          label-for="status-filter"
          class="w-100 mb-0"
        >
          <v-select
            id="status-filter"
            :value="statusFilter"
            :options="statusOptions"
            label="label"
            style="font-size: 1rem"
            searchable
            clearable
            :placeholder="$t('train.filter.statusPlaceholder')"
            :reduce="val => val.value"
            append-to-body
            :calculate-position="withPopper"
            @input="(val) => $emit('update:statusFilter', val)"
          >
            <template #selected-option="{ label }">
              {{ $t(`train.status.${label}`) }}
            </template>
            <template #option="{ label }">
              {{ $t(`train.status.${label}`) }}
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <!-- ANCHOR created by -->
      <b-col
        cols="12"
        md="4"
        lg="2"
        class="mt-75 mt-lg-0"
      >
        <b-form-group
          label-for="created-by-select"
          class="w-100 mb-0"
        >
          <!-- <label class="font-weight-bold">{{ $t('createdBy') }}</label> -->
          <v-select
            id="created-by-select"
            :options="createdByOptions"
            label="firstName"
            searchable
            :filterable="false"
            :value="createdByFilter"
            :loading="isLoadingCreatedBy"
            :placeholder="$t('reservation.filters.createdBy')"
            :reduce="val => val"
            append-to-body
            :calculate-position="withPopper"
            @open="handleOpenCreatedBy"
            @search="handleSearchCreatedBy"
            @input="(val) => $emit('update:createdByFilter', val)"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :class="{
                  'text-uppercase': attributes.value
                }"
                v-bind="attributes"
                v-on="events"
              >
            </template>
            <template #selected-option="{ firstName, lastName, username }">
              <div class="w-100 d-flex-between text-truncate">
                <span class="font-weight-bold d-block text-nowrap">
                  {{ lastName }} {{ firstName }} ({{ username }})
                </span>
              </div>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
            <template #option="{ firstName, lastName, username }">
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ lastName }} {{ firstName }} ({{ username }})
                  </span>
                </b-col>
              </b-row>
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <!-- ANCHOR agency code -->
      <b-col
        cols="12"
        md="4"
        lg="2"
        class="mt-75 mt-lg-0"
      >
        <b-form-group
          label-for="agency-code"
          class="w-100 mb-0"
        >
          <!-- <label class="font-weight-bold">{{ $t('createdBy') }}</label> -->
          <v-select
            id="agency-code"
            :options="agencyCodeOptions"
            label="agencyCode"
            searchable
            :filterable="false"
            :value="agencyCodeFilter"
            :loading="isLoadingAgencyCode"
            :placeholder="$t('reservation.filters.agencyCode')"
            :reduce="val => val"
            append-to-body
            :calculate-position="withPopper"
            @open="handleOpenAgencyCode"
            @search="handleSearchAgencyCode"
            @input="(val) => $emit('update:agencyCodeFilter', val)"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :class="{
                  'text-uppercase': attributes.value
                }"
                v-bind="attributes"
                v-on="events"
              >
            </template>

            <template #selected-option="data">
              <div class="w-100 d-flex-between text-truncate">
                <span class="font-weight-bold d-block text-nowrap">
                  <span class="text-uppercase">{{ data.agencyCode }}</span> ({{ data.agencyName }})
                </span>
              </div>
            </template>
            <template #option="data">
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <span class="font-weight-bold d-block text-nowrap">
                    <span class="text-uppercase">{{ data.agencyCode }}</span> ({{ data.agencyName }})
                  </span>
                </b-col>
              </b-row>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- ANCHOR - Reset and SEARCH -->
    <b-row class="d-flex-center">
      <!-- ANCHOR - Search PNR -->
      <b-col
        cols="12"
        md="6"
        lg="10"
        class="flex-grow-1 mt-75 pr-md-50 pr-lg-1"
        :class="isMobileView ? 'flex-column' : 'd-flex-center gap-3'"
      >
        <!-- ANCHOR searchText: tạm ẩn với F1 F2 -->
        <b-input-group class="input-group-merge mb-75 mb-md-0">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            :value="searchTextFilter"
            type="search"
            maxlength="255"
            :formatter="removeAccentsUpperCaseFormatterNotTrim"
            :placeholder="$t('train.filter.searchTextPlaceholder')"
            @input="(value) => handleDebounceSearchText(value)"
          />
        </b-input-group>

        <!-- ANCHOR bookingCode -->
        <!-- v-if="!isRoleF3" -->
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="input-booking-code"
            :value="bookingCodeFilter"
            type="search"
            maxlength="6"
            minlength="6"
            :formatter="removeAccentsUpperCaseFormatter"
            :placeholder="$t('train.filter.bookingCodePlaceholder')"
            @input="(value) => handleDebounceBookingCode(value)"
          />
          <b-tooltip
            v-if="appBreakPoint !== 'xs'"
            id="tooltip-booking-code"
            target="input-booking-code"
            triggers="hover focus"
            placement="top"
            boundary="viewport"
            variant="info"
            style="width: max-content;"
          >
            <div
              class="text-white"
              style="font-size: 15px"
            >
              Nhập chính xác <span class="font-weight-bolder">Mã đặt chỗ</span> (Gồm 6 ký tự) để tìm kiếm
            </div>
          </b-tooltip>
        </b-input-group>
      </b-col>

      <!-- ANCHOR - BUTTONs -->
      <b-col
        cols="12"
        lg="2"
        class="mt-75 d-flex-center flex-row-reverse gap-2"
      >
        <!-- <b-button
          v-if="isExport"
          class="font-weight-bolder text-primary d-flex-center gap-1"
          variant="outline-primary"
          @click="openModalFilterToExport"
        >
          <feather-icon
            v-if="!isMobileView"
            icon="DownloadIcon"
          />
          <span>{{ $t('export') }}</span>
        </b-button> -->

        <b-button
          class="font-weight-bolder d-flex-center gap-1 px-50"
          variant="outline-danger"
          :disabled="emptyFilter"
          @click="$emit('reset'), resetFilter()"
        >
          <feather-icon
            v-if="!isMobileView"
            icon="TrashIcon"
          />
          {{ $t('train.filter.clearFilter') }}
        </b-button>

        <b-button
          class="font-weight-bolder d-flex-center gap-1 px-50 flex-1"
          variant="outline-warning"
          :disabled="emptyFilter"
          @click="$emit('fetch-data')"
        >
          <feather-icon
            v-if="!isMobileView"
            icon="SearchIcon"
          />
          {{ $t('search') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { debounce } from 'lodash'
import { ref, computed, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

import store from '@/store'
import api from '@/api'
import { isActiveOptions } from '@/constants/selectOptions'
import VueI18n from '@/libs/i18n'

import { removeAccentsUpperCaseFormatter, removeAccentsUpperCaseFormatterNotTrim } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BTooltip,
    flatPickr,
    vSelect,
  },
  props: {
    emptyFilter: {
      type: Boolean,
      default: null,
    },
    startDateFilter: {
      type: String,
      default: null,
    },
    endDateFilter: {
      type: String,
      default: null,
    },
    statusFilter: {
      type: String,
      default: null,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    createdByFilter: {
      type: [Object, null],
      default: () => { },
    },
    agencyCodeFilter: {
      type: [Object, null],
      default: () => { },
    },
    searchTextFilter: {
      type: String,
      default: '',
    },
    bookingCodeFilter: {
      type: String,
      default: '',
    },
    // isExport: {
    //   type: Boolean,
    //   default: true,
    // },
  },

  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownWidth = `calc(${Number(width.split('px')[0]) + 100}px )` || width // + 25px
      dropdownList.style.width = dropdownWidth
      dropdownList.style.translate = `calc((${dropdownWidth} - ${this.right ? '0' : width})/2${this.right ? '*(-1)' : ''})`
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
      })
      return () => popper.destroy()
    },
  },
  setup(_, { emit }) {
    const handleDebounceSearchText = debounce(searchText => {
      emit('update:searchTextFilter', searchText)
    }, 300)

    const handleDebounceBookingCode = debounce(bookingCode => {
      if (![0, 6].includes(bookingCode.length)) return
      emit('update:bookingCodeFilter', bookingCode)
    }, 300)

    // function openModalFilterToExport() {
    //   this.$bvModal.show('modal-reservation-booking-export-excel')
    // }

    const createdByOptions = ref([])
    const agencyCodeOptions = ref([])
    const isLoadingCreatedBy = ref(false)
    const isLoadingAgencyCode = ref(false)
    const createdByOptionsDefault = ref([])
    const agencyCodeOptionsDefault = ref([])

    const fetchEmployeesByFilterSearch = async (search = '') => {
      createdByOptions.value = []
      isLoadingCreatedBy.value = true
      try {
        const data = await api.employee.fetchEmployeesByFilter({ searchText: search })
        if (data && !search) {
          createdByOptionsDefault.value = data.items
        }
        createdByOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoadingCreatedBy.value = false
      }
    }

    function handleOpenCreatedBy() {
      if (createdByOptionsDefault.value.length === 0) {
        fetchEmployeesByFilterSearch()
      } else {
        createdByOptions.value = createdByOptionsDefault.value
      }
    }

    const handleSearchCreatedBy = debounce(search => {
      if (search) fetchEmployeesByFilterSearch(search)
    }, 300)

    const fetchAgenciesByFilterSearch = async (search = '') => {
      agencyCodeOptions.value = []
      isLoadingAgencyCode.value = true
      try {
        const { data } = await api.agencies.fetchAgencies({ searchText: search })
        if (data && !search) {
          agencyCodeOptionsDefault.value = data.items
        }
        agencyCodeOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoadingAgencyCode.value = false
      }
    }
    function handleOpenAgencyCode() {
      if (agencyCodeOptionsDefault.value.length === 0) {
        fetchAgenciesByFilterSearch()
      } else {
        agencyCodeOptions.value = agencyCodeOptionsDefault.value
      }
    }

    const handleSearchAgencyCode = debounce(search => {
      if (search) fetchAgenciesByFilterSearch(search)
    }, 300)
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const rangeDate = ref([])
    const rangeTripDate = ref([])

    watch(rangeDate, value => {
      if (!value.length) return
      const sepSign = VueI18n.locale === 'vi' ? ' đến ' : ' to '
      const dateRange = value.split(sepSign)
      if (dateRange.length > 1) {
        emit('update:startDateFilter', dateRange[0])
        emit('update:endDateFilter', dateRange[1])
      } else if (dateRange.length === 1) {
        emit('update:startDateFilter', dateRange[0])
        emit('update:endDateFilter', dateRange[0])
      }
    }, { deep: true, immediate: true })

    watch(rangeTripDate, value => {
      if (!value.length) return
      const sepSign = VueI18n.locale === 'vi' ? ' đến ' : ' to '
      const dateRange = value.split(sepSign)
      if (dateRange.length > 1) {
        emit('update:startTripDateFilter', dateRange[0])
        emit('update:endTripDateFilter', dateRange[1])
      } else if (dateRange.length === 1) {
        emit('update:startTripDateFilter', dateRange[0])
        emit('update:endTripDateFilter', dateRange[0])
      }
    }, { deep: true, immediate: true })

    function resetFilter() {
      rangeDate.value = []
      rangeTripDate.value = []
    }
    return {
      Vietnamese,
      handleDebounceSearchText,
      handleDebounceBookingCode,
      // openModalFilterToExport,
      handleSearchCreatedBy,
      handleOpenCreatedBy,
      createdByOptions,
      isLoadingCreatedBy,
      isLoadingAgencyCode,
      removeAccentsUpperCaseFormatter,
      isRoleF3,
      isActiveOptions,

      agencyCodeOptions,
      handleOpenAgencyCode,
      handleSearchAgencyCode,
      removeAccentsUpperCaseFormatterNotTrim,

      rangeDate,
      rangeTripDate,
      resetFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
#range-date ::v-deep {
  input.form-control.input {
    border-radius: 0.357rem 0 0 0.357rem;
    border-right: none;
  }
}

#tooltip-booking-code ::v-deep {
  .tooltip-inner {
    max-width: fit-content;
  }
}
</style>
