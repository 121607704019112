var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-50"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0"
  }, [_c('b-input-group', {
    attrs: {
      "id": "range-date"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control form-control-md",
    attrs: {
      "config": {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        class: 'form-control-md',
        altInput: true,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      },
      "placeholder": _vm.$t('train.filter.bookDatePlaceholder')
    },
    model: {
      value: _vm.rangeDate,
      callback: function callback($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-75 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0"
  }, [_c('b-input-group', {
    attrs: {
      "id": "range-date"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control form-control-md",
    attrs: {
      "config": {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        altInput: true,
        class: 'form-control-md',
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      },
      "placeholder": _vm.$t('train.filter.tripDatePlaceholder')
    },
    model: {
      value: _vm.rangeTripDate,
      callback: function callback($$v) {
        _vm.rangeTripDate = $$v;
      },
      expression: "rangeTripDate"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-75 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label-for": "status-filter"
    }
  }, [_c('v-select', {
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "status-filter",
      "value": _vm.statusFilter,
      "options": _vm.statusOptions,
      "label": "label",
      "searchable": "",
      "clearable": "",
      "placeholder": _vm.$t('train.filter.statusPlaceholder'),
      "reduce": function reduce(val) {
        return val.value;
      },
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:statusFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref) {
        var label = _ref.label;
        return [_vm._v(" " + _vm._s(_vm.$t("train.status.".concat(label))) + " ")];
      }
    }, {
      key: "option",
      fn: function fn(_ref2) {
        var label = _ref2.label;
        return [_vm._v(" " + _vm._s(_vm.$t("train.status.".concat(label))) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-75 mt-lg-0",
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label-for": "created-by-select"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "created-by-select",
      "options": _vm.createdByOptions,
      "label": "firstName",
      "searchable": "",
      "filterable": false,
      "value": _vm.createdByFilter,
      "loading": _vm.isLoadingCreatedBy,
      "placeholder": _vm.$t('reservation.filters.createdBy'),
      "reduce": function reduce(val) {
        return val;
      },
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    on: {
      "open": _vm.handleOpenCreatedBy,
      "search": _vm.handleSearchCreatedBy,
      "input": function input(val) {
        return _vm.$emit('update:createdByFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "search",
      fn: function fn(_ref3) {
        var attributes = _ref3.attributes,
          events = _ref3.events;
        return [_c('input', _vm._g(_vm._b({
          staticClass: "vs__search",
          class: {
            'text-uppercase': attributes.value
          }
        }, 'input', attributes, false), events))];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref4) {
        var firstName = _ref4.firstName,
          lastName = _ref4.lastName,
          username = _ref4.username;
        return [_c('div', {
          staticClass: "w-100 d-flex-between text-truncate"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " (" + _vm._s(username) + ") ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref5) {
        var loading = _ref5.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "option",
      fn: function fn(_ref6) {
        var firstName = _ref6.firstName,
          lastName = _ref6.lastName,
          username = _ref6.username;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "9"
          }
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " (" + _vm._s(username) + ") ")])])], 1)];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-75 mt-lg-0",
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label-for": "agency-code"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "agency-code",
      "options": _vm.agencyCodeOptions,
      "label": "agencyCode",
      "searchable": "",
      "filterable": false,
      "value": _vm.agencyCodeFilter,
      "loading": _vm.isLoadingAgencyCode,
      "placeholder": _vm.$t('reservation.filters.agencyCode'),
      "reduce": function reduce(val) {
        return val;
      },
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    on: {
      "open": _vm.handleOpenAgencyCode,
      "search": _vm.handleSearchAgencyCode,
      "input": function input(val) {
        return _vm.$emit('update:agencyCodeFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "search",
      fn: function fn(_ref7) {
        var attributes = _ref7.attributes,
          events = _ref7.events;
        return [_c('input', _vm._g(_vm._b({
          staticClass: "vs__search",
          class: {
            'text-uppercase': attributes.value
          }
        }, 'input', attributes, false), events))];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex-between text-truncate"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_c('span', {
          staticClass: "text-uppercase"
        }, [_vm._v(_vm._s(data.agencyCode))]), _vm._v(" (" + _vm._s(data.agencyName) + ") ")])])];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "9"
          }
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_c('span', {
          staticClass: "text-uppercase"
        }, [_vm._v(_vm._s(data.agencyCode))]), _vm._v(" (" + _vm._s(data.agencyName) + ") ")])])], 1)];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref8) {
        var loading = _ref8.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "d-flex-center"
  }, [_c('b-col', {
    staticClass: "flex-grow-1 mt-75 pr-md-50 pr-lg-1",
    class: _vm.isMobileView ? 'flex-column' : 'd-flex-center gap-3',
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "10"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge mb-75 mb-md-0"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "value": _vm.searchTextFilter,
      "type": "search",
      "maxlength": "255",
      "formatter": _vm.removeAccentsUpperCaseFormatterNotTrim,
      "placeholder": _vm.$t('train.filter.searchTextPlaceholder')
    },
    on: {
      "input": function input(value) {
        return _vm.handleDebounceSearchText(value);
      }
    }
  })], 1), _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "input-booking-code",
      "value": _vm.bookingCodeFilter,
      "type": "search",
      "maxlength": "6",
      "minlength": "6",
      "formatter": _vm.removeAccentsUpperCaseFormatter,
      "placeholder": _vm.$t('train.filter.bookingCodePlaceholder')
    },
    on: {
      "input": function input(value) {
        return _vm.handleDebounceBookingCode(value);
      }
    }
  }), _vm.appBreakPoint !== 'xs' ? _c('b-tooltip', {
    staticStyle: {
      "width": "max-content"
    },
    attrs: {
      "id": "tooltip-booking-code",
      "target": "input-booking-code",
      "triggers": "hover focus",
      "placement": "top",
      "boundary": "viewport",
      "variant": "info"
    }
  }, [_c('div', {
    staticClass: "text-white",
    staticStyle: {
      "font-size": "15px"
    }
  }, [_vm._v(" Nhập chính xác "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v("Mã đặt chỗ")]), _vm._v(" (Gồm 6 ký tự) để tìm kiếm ")])]) : _vm._e()], 1)], 1), _c('b-col', {
    staticClass: "mt-75 d-flex-center flex-row-reverse gap-2",
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_c('b-button', {
    staticClass: "font-weight-bolder d-flex-center gap-1 px-50",
    attrs: {
      "variant": "outline-danger",
      "disabled": _vm.emptyFilter
    },
    on: {
      "click": function click($event) {
        _vm.$emit('reset'), _vm.resetFilter();
      }
    }
  }, [!_vm.isMobileView ? _c('feather-icon', {
    attrs: {
      "icon": "TrashIcon"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t('train.filter.clearFilter')) + " ")], 1), _c('b-button', {
    staticClass: "font-weight-bolder d-flex-center gap-1 px-50 flex-1",
    attrs: {
      "variant": "outline-warning",
      "disabled": _vm.emptyFilter
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('fetch-data');
      }
    }
  }, [!_vm.isMobileView ? _c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }